import { post } from '@/utils/request'

//广告图列表
export function PostPrictureList(data) {
    return post('admin/banner/link', data)
}
//上传广告图
export function PostPrictureAdd(data) {
    return post('admin/banner/link/add', data)
}
//广告图删除
export function PostPrictureDel(data) {
    return post('admin/banner/link/delete', data)
}
//广告图详情
export function PostPrictureDetail(data) {
    return post('admin/banner/link/info', data)
}
//广告图编辑
export function PostPrictureUpdata(data) {
    return post('admin/banner/link/edit', data)
}
//轮播图id列表
export function PostPrictureListID() {
    return post('admin/banner/list2')
}
//商品id列表
export function PostPrictureGoodId() {
    return post('admin/banner/link/goods')
}
//轮播图分类列表
export function getBannerCatList(data) {
    return post('admin/bannerCat/list',data)
  }
  //轮播图分类添加
  export function getBannerCatAdd(data) {
      return post('admin/bannerCat/add',data)
    }
    //轮播图分类删除
  export function getBannerCatDel(data) {
      return post('admin/bannerCat/del',data)
 }