<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
        <el-tooltip
          class="item"
          effect="dark"
          content="上传广告图"
          placement="top-start"
        >
          <el-button @click="addPicture" type="primary">上传广告图</el-button>
        </el-tooltip>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
        <!-- <el-table-column
          align="center"
          width="100px"
          prop="id"
          label="广告图ID"
        ></el-table-column> -->
        <!-- <el-table-column
          align="center"
          width="120px"
          prop="banner_id"
          label="轮播图ID"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="goods_name"
          label="商品名称"
        ></el-table-column>
        <el-table-column align="center" prop="img_url" label="广告图">
          <template slot-scope="scope">
            <div class="imgUrl">
              <img class="image" :src="scope.row.img_url" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <div class="block">
              <el-tag type="success" v-if="scope.row.status">显示</el-tag>
              <el-tag type="success" v-else>隐藏</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看广告图详情"
              placement="top-start"
            >
              <el-button
                icon="el-icon-search"
                @click="lookPicture(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改广告图"
              placement="top-start"
            >
              <el-button
                icon="el-icon-edit"
                type="primary"
                @click="updataPicture(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除广告图"
              placement="top-start"
            >
              <el-button
                icon="el-icon-delete"
                type="danger"
                @click="delPicture(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
    <!-- 上传广告图 -->
    <el-dialog
      :visible.sync="AddDialogFormVisible"
      :append-to-body="true"
      :before-close="AddhandleClose"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">上传广告图</div>
        <div class="title" v-show="!openState">修改广告图</div>
      </div>
      <div class="diaBox">
        <el-form
          :model="addPicureForm"
          label-width="100px"
          :rules="rules"
          ref="ruleForm"
        >
          <!-- <el-form-item label="广告图ID" prop="id" v-if="!openState">
            <el-select v-model="addPicureForm.id" clearable placeholder="请选择">
              <el-option
                v-for="item in optionsid"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="轮播图标题" prop="ids">
            <el-select
              filterable
              v-model="addPicureForm.banner_id"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in bannerids"
                :key="item.value"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称" prop="idss">
            <el-cascader
              :options="goodsids"
              :props="optionProps"
              clearable
              v-model="addPicureForm.goods_id"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="广告图" prop="imgUrl">
            <el-upload
              :headers="token"
              accept=".jpg,.png"
              :on-remove="imgRemove"
              :limit="1"
              :action="imgUrl"
              list-type="picture"
              :on-exceed="exceed"
              :on-success="imgSuccess"
              name="file"
              :file-list="fileList"
              :data="imgParam"
            >
              <el-button size="small" type="primary">上传轮播图</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="setAdd('ruleForm')" v-show="openState"
          >上传</el-button
        >
        <el-button
          type="primary"
          @click="setEdit('ruleForm')"
          v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看广告图详情 -->
    <el-dialog
      :visible.sync="lookDialogFormVisible"
      :append-to-body="true"
      :before-close="lookhandleClose"
    >
      <div slot="title" class="dialog-footer">
        <div class="title">广告图详情</div>
      </div>
      <div class="diaBox">
        <el-form :model="addPicureForm" label-width="100px">
           <el-form-item label="轮播图标题" prop="ids">
            <el-select
              filterable
              v-model="addPicureForm.banner_id"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in bannerids"
                :key="item.value"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称" prop="idss">
            <el-cascader
              :options="goodsids"
              :props="optionProps"
              clearable
              v-model="addPicureForm.goods_id"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="广告图" prop="imgUrl">
            <el-upload
              :headers="token"
              accept=".jpg,.png"
              :on-remove="imgRemove"
              :limit="1"
              :action="imgUrl"
              list-type="picture"
              :on-exceed="exceed"
              :on-success="imgSuccess"
              name="file"
              :file-list="fileList"
              :data="imgParam"
            >
              <el-button size="small" type="primary">上传轮播图</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="状态">
            <el-input
              v-model="addPicureForm.status"
              placeholder=""
              style="width: 200px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="YesLook">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  PostPrictureList,
  PostPrictureAdd,
  PostPrictureDel,
  PostPrictureDetail,
  PostPrictureUpdata,
  PostPrictureListID,
  PostPrictureGoodId,
} from "@/api/pricture/prictureList.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    var validateID = (rule, value, callback) => {
      if (this.addPicureForm.id === "") {
        callback(new Error("广告图ID不能为空！"));
      } else {
        callback();
      }
    };
    var validateIDs = (rule, value, callback) => {
      if (this.addPicureForm.banner_id === "") {
        callback(new Error("轮播图ID不能为空！"));
      } else {
        callback();
      }
    };
    var validateIDss = (rule, value, callback) => {
      if (this.addPicureForm.banner_id === "") {
        callback(new Error("商品ID不能为空！"));
      } else {
        callback();
      }
    };
    var validateimgUrl = (rule, value, callback) => {
      if (this.addPicureForm.img_url === "") {
        callback(new Error("请上传广告图！"));
      } else {
        callback();
      }
    };
    //这里存放数据
    return {
      optionProps: {
        value: "id",
        label: "name",
        children: "sub",
      },
      //轮播图选择框
      bannerids: [],
      //商品id选择框
      goodsids: [],
      rules: {
        id: [{ validator: validateID, trigger: "blur" }],
        ids: [{ validator: validateIDs, trigger: "blur" }],
        idss: [{ validator: validateIDss, trigger: "blur" }],
        imgUrl: [{ validator: validateimgUrl, trigger: "blur" }],
      },
      fileList: [], //上传图片的列表
      imgParam: {
        path: "banner",
      },
      token: {
        token: window.sessionStorage.getItem("token"),
      },
      AddDialogFormVisible: false, //上传轮播图模态框
      lookDialogFormVisible: false, //查看详情模态框
      openState: true, //上传还是修改flag
      addPicureForm: {
        id: "", //广告图id
        banner_id: "", //轮播图ID
        goods_id: "", //商品ID
        img_url: "", //广告图地址
        status: "", //状态
      },
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [],
      //家庭Id
      familyId: "", //家庭ID
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {
    AddDialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.addPicureForm = this.$options.data().addPicureForm;
        this.fileList = this.$options.data().fileList;
      }
    },
    lookDialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.addPicureForm = this.$options.data().addPicureForm;
        this.fileList = this.$options.data().fileList;
      }
    },
  },
  //方法集合
  methods: {
    exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    YesLook() {
      this.lookDialogFormVisible = false;
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      console.log(response);
      this.addPicureForm.img_url = response.data.url;
    },

    //移除图片的事件
    imgRemove(file, fileList) {
      this.addPicureForm.img_url = "";
    },
    //上传轮播图按钮
    addPicture() {
      this.AddDialogFormVisible = true;
      this.openState = true;
      console.log("上传轮播图按钮");
    },
    //取消模态框
    cancel() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.AddDialogFormVisible = false;
        })
        .catch((_) => {});
    },
    //模态框上传轮播图按钮
    setAdd(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确认上传？")
            .then((_) => {
              that.AddDialogFormVisible = false;
              that.tableData = [];
              if(that.addPicureForm.goods_id.length==3){
                that.addPicureForm.goods_id=that.addPicureForm.goods_id[2];
              }else{
                that.addPicureForm.goods_id='';
              }
              that.PostPrictureAdd(this.addPicureForm);
              console.log(that.addPicureForm);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      console.log("上传轮播图");
    },
    //模态框修改轮播图按钮
    setEdit(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("确认修改？")
            .then((_) => {
              that.AddDialogFormVisible = false;
              that.tableData = [];
              //  console.log(this.addPicureForm)
              if(that.addPicureForm.goods_id.length==3){
                that.addPicureForm.goods_id=that.addPicureForm.goods_id[2];
              }else{
                that.addPicureForm.goods_id='';
              }
              that.PostPrictureUpdata(this.addPicureForm);
              console.log(that.addPicureForm);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //查看广告图
    lookPicture(item) {
      this.lookDialogFormVisible = true;
      this.addPicureForm = {
        id: item.id, //广告图id
        banner_id: item.banner_id, //轮播图ID
        goods_id: item.goods_id, //商品ID
        img_url: item.img_url, //广告图地址
        status: item.status, //状态
      };
      this.fileList = [{ url: item.img_url }];
      console.log(item);
    },
    //修改广告图
    updataPicture(item) {
      this.addPicureForm = {
        id: item.id, //广告图id
        banner_id: item.banner_id, //轮播图ID
        goods_id: item.goods_id, //商品ID
        img_url: item.img_url, //广告图地址
        status: item.status, //状态
      };
      this.openState = false;
      this.AddDialogFormVisible = true;
      this.fileList = [{ url: item.img_url }];
    },
    //删除广告图
    delPicture(item) {
      var that = this;
      console.log(item);
      var data = {
        link_id: item,
      };
      this.$confirm("确认删除？")
        .then((_) => {
          that.AddDialogFormVisible = false;
          that.tableData = [];
          that.PostPrictureDel(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.PostPrictureList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.PostPrictureList(data);
    },
    //点击模态层 隐藏上传模态框
    AddhandleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //点击模态层 隐藏查看模态框
    lookhandleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    /**-----------------网络请求生命周期函数--------------------- */
    //商品列表
    async PostPrictureGoodId() {
      let res = await PostPrictureGoodId();
      if (res.data.code == 1) {
         this.goodsids=res.data.data;
         console.log(res.data.data)
      } else {
        this.$message.error(res.data.message);
      }
      console.log(res, "商品列表");
    },
    //轮播图列表
    async PostPrictureListID() {
      let res = await PostPrictureListID();
      if (res.data.code == 1) {
        this.bannerids = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
      console.log(res, "轮播图列表");
    },
    //删除广告图
    PostPrictureDel(data) {
      let res = PostPrictureDel(data);
      res
        .then((res) => {
          if (res.data.code == 1) {
            var data = {
              page: this.getInfo.page_code,
              limit: this.getInfo.page_num,
            };
            this.PostPrictureList(data);
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //上传广告图
    PostPrictureAdd(data) {
      console.log(data);
      let res = PostPrictureAdd(data);
      res
        .then((res) => {
          if (res.data.code == 1) {
            var data = {
              page: this.getInfo.page_code,
              limit: this.getInfo.page_num,
            };
            this.PostPrictureList(data);
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //修改广告图
    PostPrictureUpdata(data) {
      console.log(data);
      let res = PostPrictureUpdata(data);
      res
        .then((res) => {
          if (res.data.code == 1) {
            var data = {
              page: this.getInfo.page_code,
              limit: this.getInfo.page_num,
            };
            this.PostPrictureList(data);
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取广告图管理列表
    async PostPrictureList(data) {
      let res = await PostPrictureList(data);
      if (res.data.code == 1) {
        this.exp.num = res.data.data.total;
        this.tableData = this.tableData.concat(res.data.data.data);
        console.log(res.data);
      } else {
        this.$message.error(res.data.message);
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data = {
      page: this.getInfo.page_code,
      limit: this.getInfo.page_num,
    };
    this.PostPrictureList(data);
    //轮播图列表
    this.PostPrictureListID();
    //商品列表
     this.PostPrictureGoodId();
  },

  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
.imgUrl {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 200px;
  overflow: hidden;
  text-align: center;
}
/* .image{
  width: 100%;
  height: 100%;
} */
</style>